export const STATUS_SCHEDULED = 'scheduled';
export const STATUS_BLOCKED = 'blocked';
export const STATUS_AVAILABLE = 'available';

export const statuses = [
  {
    key: STATUS_SCHEDULED,
    name: 'Agendado',
    description: 'Agendamento criado',
    color: '#F1E87A',
  },
  {
    key: STATUS_BLOCKED,
    name: 'Bloqueado',
    description: 'Horário bloqueado',
    color: '#9B9898',
  },
  {
    key: 'patient_canceled',
    name: 'Cancelado pelo paciente',
    description: 'Agendamento cancelado pelo paciente',
    color: '#ff9696',
  },
  {
    key: 'professional_canceled',
    name: 'Cancelado pelo profissional',
    description: 'Agendamento cancelado pelo profissional',
    color: '#ff9696',
  },
  {
    key: 'confirmed',
    name: 'Confirmado',
    description: 'Agendamento confirmado',
    color: '#00B6AE',
  },
  {
    key: 'unsuccessful',
    name: 'Contato sem sucesso',
    description: 'Tentativa de contato sem sucesso',
    color: '#8b68ce',
  },
  {
    key: STATUS_AVAILABLE,
    name: 'Disponível',
    description: 'Horário disponível',
    color: '#F9F9F9',
  },
  {
    key: 'in_attendance',
    name: 'Em Atendimento',
    description: 'Atendimento iniciado',
    color: '#20F8FF',
  },
  {
    key: 'waiting',
    name: 'Em Espera',
    description: 'Paciente colocado em espera',
    color: '#E8A363',
  },
  {
    key: 'missed',
    name: 'Falta',
    description: 'Paciente faltou',
    color: '#f85c5c',
  },
  {
    key: 'finished',
    name: 'Finalizado',
    description: 'Atendimento finalizado',
    color: '#3197FF',
  },
  {
    key: 'report',
    name: 'Laudo',
    description: 'Marcado para laudo',
    color: '#30FF9B',
  },
  {
    key: 'payment',
    name: 'Pagamento',
    description: 'Aguardando pagamento',
    color: '#b74aff',
  },
  {
    key: 'rescheduled',
    name: 'Remarcado',
    description: 'Agendamento remarcado',
    color: '#e895ff',
  },
  {
    key: 'screening',
    name: 'Triagem',
    description: 'Paciente em triagem',
    color: '#B4FF00',
  },
];

export function get(key) {
  return statuses.find(item => item.key === key);
}

export function getColor(key) {
  const status = get(key);
  return status ? status.color : null;
}

export function getName(key) {
  const status = get(key);
  return status ? status.name : null;
}

export function getDescription(key) {
  const status = get(key);
  return status ? status.description : null;
}

export default statuses.filter(({ key }) => !['available', 'blocked'].includes(key));
